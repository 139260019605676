<template>
  <Loader :value="promises.fetch" class="post-subpage">
    <Blocks v-if="item.posts" :blocks="config" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// pagebuilder
import Config from '@/config/postsSubpage'
import { Blocks } from '@kvass/pagebuilder'

export default {
  props: {
    postSlug: {
      type: String,
      default: 'post',
    },
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']

      return Config[sources[0]].call(this)
    },
    post() {
      if (!this.item.posts) return {}

      const post = this.item.posts.find(p => p.slug === this.slug)

      // redirect
      if (!post) {
        this.$router.push({ name: 'Page' })
      }

      const parent = this.item.posts.find(p => p.slug === this.postSlug)

      if (
        post.customFieldsConfig[0].source === 'article' &&
        parent.slug !== this.postSlug &&
        parent.customFieldsConfig[0].source === 'news'
      ) {
        this.$router.push({ name: 'Page' })
      }
      if (
        post.customFieldsConfig[0].source === 'upcoming-project' &&
        parent.slug !== this.postSlug &&
        parent.customFieldsConfig[0].source === 'project-overview'
      ) {
        this.$router.push({ name: 'Page' })
      }

      return post
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-subpage {
  .section-breadcrumbs-top {
    @include respond-below('phone') {
      display: none;
    }
  }

  .article {
    &__breadcrumbs {
      text-underline-offset: 0.2rem;

      &--link {
        &:after {
          content: url("data:image/svg+xml,%3Csvg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.625 8.75L4.375 5L0.625 1.25' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          padding: 0 0.5rem;
        }
      }
    }

    &__date {
      color: var(--brand-primary-muted);
    }
  }

  .kpb-section--theme-primary svg path {
    fill: var(--brand-secondary) !important;
  }

  .section-breadcrumbs-bottom {
    padding-block: 3rem;

    &__flex {
      flex-direction: row;
    }

    &__additional-section--theme {
      &-white {
        padding-block: 1rem;
      }

      &-none {
        padding-block: 1rem;
      }
    }

    .link-to-article {
      font-size: 1.3em;

      color: var(--primary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @include respond-below('phone') {
        font-size: 1.2rem;
        svg {
          width: 8px;
          height: 14px;
        }
      }
    }
  }
}
</style>
